<template>
  <div></div>
</template>

<script>
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../../../assets/build/vfs_fonts";
import apiConfig from "../../../config/api";

import moment from "moment";

import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";

import { ArabicNumberToText } from "../../../utils/numberToText";

import JsBarcode from "jsbarcode/bin/JsBarcode";

export default {
  props: {
    dataExport: Object,
    orderForm: String,
  },
  data: () => ({
    newProduct: [],
    user: "",
    numberToTh: "",
    sumRequestStockAmt: null,
  }),
  async created() {
    console.log(this.dataExport);
    // await this.getUser();
    await this.exports();
  },

  methods: {
    async getUser() {
      const userId = Decode.decode(localStorage.getItem("boss"));

      let responseData = [];

      try {
        responseData = await userApi.user.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        const users = responseData.data;
        const findUser = users.find((data) => data.id === parseInt(userId));
        if (findUser) {
          this.user = findUser;
        }
      }
    },

    textToBase64Barcode(text) {
      let canvas = document.createElement("canvas");
      JsBarcode(canvas, text, { format: "CODE39", displayValue: false });

      return canvas.toDataURL("image/png");
    },

    exports() {
      const newWindow = window.open("", "_blank");

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          // normal: "Kanit-Regular.ttf",
          // bold: "Kanit-Medium.ttf",
          normal: "Tahoma-Regular.ttf",
          bold: "Tahoma-Bold.ttf",
          italics: "Kanit-Medium.ttf",
          bolditalics: "Kanit-Medium.ttf",
        },
      };

      //setProduct Arr
      this.setProduct();
      this.setTotal();

      // convert num to text TH
      // this.convertNumberToTh();

      const html = [
        {
          text: `รายงานสรุปสินค้า ${this.orderForm}`,
          fontSize: 14,
          alignment: "center",
          bold: true,
        },
        // {
        //   text: "WAREHOUSE TO WAREHOUSE",
        //   margin: [0, 0, 0, 10],
        //   fontSize: 14,
        // },

        // {
        //   columns: [
        //     {
        //       width: "50%",
        //       text: `จากคลัง ${this.dataExport.form_warehouse_id}`,
        //       fontSize: 10,
        //     },
        //     {
        //       width: "50%",
        //       text: `ถึงคลัง ${this.dataExport.to_warehouse_id}`,
        //       alignment: "right",
        //       fontSize: 10,
        //     },
        //   ],
        //   columnGap: 10,
        //   margin: [0, 0, 0, 5],
        // },

        {
          columns: [
            {
              width: "30%",
              text: `วันที่ ${moment(this.dataExport.createdAt).format(
                "DD/MM/YYYY"
              )}`,
              fontSize: 10,
            },
            {
              width: "70%",
              text: `VAN ${
                this.dataExport.van
                  ? this.dataExport.van.van_code
                  : // ? this.dataExport.vanId
                    // : this.dataExport.vanId
                    "-"
              }`,
              alignment: "right",
              fontSize: 10,
            },
          ],
          columnGap: 10,
          margin: [0, 20, 0, 2],
        },

        // {
        //   table: {
        //     widths: ["75%", "25%"],
        //     body: [
        //       [
        //         {
        //           text: `จากคลัง ${this.dataExport.fromWareHouse.warehouse_short_name} ${this.dataExport.fromWareHouse.warehouse_full_name}`,
        //           fontSize: 10,
        //           border: [false, false, false, false],
        //           margin: [-5, 0, 0, 0],
        //         },

        //         {
        //           rowSpan: 2,
        //           image: this.textToBase64Barcode(
        //             `${this.dataExport.transaction_number}`
        //           ),
        //           // qr: `${apiConfig.ENDPOINT_CLIENT}#/purchase/PU2-1/edit?id=${this.dataExport.id}`,
        //           width: 130,
        //           height: 35,
        //           border: [false, false, false, false],
        //           margin: [0, 0, -5, 0],
        //         },
        //       ],
        //       [
        //         {
        //           text: `ถึงคลัง ${this.dataExport.toWareHouse.warehouse_short_name} ${this.dataExport.toWareHouse.warehouse_full_name}`,
        //           fontSize: 10,
        //           border: [false, false, false, false],
        //           margin: [-5, 0, 0, 0],
        //         },
        //         "star-sized",
        //       ],
        //     ],
        //   },
        // },

        // LINE SOLID
        // {
        //   canvas: [
        //     {
        //       type: "line",
        //       x1: 0,
        //       y1: 5,
        //       x2: 595 - 2 * 40,
        //       y2: 5,
        //       lineWidth: 1,
        //     },
        //   ],
        //   margin: [0, 0, 0, 5],
        // },

        // TABLE
        {
          style: "tableExample",
          table: {
            widths: [20, 100, "*", 50, 50, 50],
            body: this.newProduct,
          },
        },

        // REMARK

        {
          columns: [
            {
              width: 396.5,
              columns: [{ text: "" }],
            },
            {
              table: {
                widths: ["*", "*"],
                heights: 20,

                body: [
                  [
                    {
                      text: "",
                      border: [false, false, false, false],
                      alignment: "right",
                    },
                    {
                      text: this.sumRequestStockAmt
                        ? this.sumRequestStockAmt
                        : "",
                      border: [false, false, false, false],
                      alignment: "right",
                    },
                  ],
                  [
                    { text: "", border: [false, false, false, false] },
                    { text: "", border: [false, false, false, false] },
                  ],
                ],
              },
            },
          ],
        },

        // THAI TEXT
        // {
        //   columns: [
        //     {
        //       table: {
        //         widths: ["50%", "50%"],
        //         body: [
        //           [
        //             {
        //               text: "จำนวนเงินรวมทั้งสิ้น",
        //               fontSize: 14,
        //               alignment: "right",
        //               border: [false, false, false, false],
        //               bold: true,
        //             },
        //             {
        //               text: this.numberToTh,
        //               fontSize: 14,
        //               alignment: "right",
        //               border: [false, false, false, false],
        //               margin: [0, 0, -5, 0],
        //               bold: true,
        //             },
        //           ],
        //         ],
        //       },
        //     },
        //   ],
        //   columnGap: 10,
        //   margin: [0, 10, 0, 5],
        // },

        // APPROVE
        {
          columns: [
            {
              text: "",
            },
            {
              text: "Checker",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "center",
            },
            {
              text: "Receiver",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "center",
            },
          ],
          columnGap: 2,
          margin: [0, 0, 0, 10],
        },

        // SIGNATURE
        {
          columns: [
            {
              text: "",
            },
            {
              text: "...............................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "center",
            },
            {
              text: "...............................................................",
              fontSize: 10,
              margin: [0, 10, 0, 0],
              alignment: "center",
            },
          ],
          columnGap: 10,
          margin: [0, 0, 0, 5],
        },
        // {
        //   columns: [
        //     {
        //       width: "50%",
        //       text: "(..............................................................................................)",
        //       fontSize: 10,
        //       margin: [0, 10, 0, 0],
        //     },
        //     {
        //       width: "50%",
        //       text: "(..............................................................................................)",
        //       fontSize: 10,
        //       margin: [0, 10, 0, 0],
        //       alignment: "right",
        //     },
        //   ],
        //   columnGap: 10,
        //   margin: [0, 0, 0, 5],
        // },
        // {
        //   columns: [
        //     {
        //       width: "50%",
        //       text: "วันที่/Date........................................................................",
        //       fontSize: 10,
        //       margin: [0, 10, 0, 0],
        //     },
        //     {
        //       width: "50%",
        //       text: "วันที่/Date........................................................................",
        //       fontSize: 10,
        //       margin: [0, 10, 0, 0],
        //       alignment: "right",
        //     },
        //   ],
        //   columnGap: 10,
        //   margin: [0, 0, 0, 5],
        // },
      ];

      const pdfData = {
        content: html,
      };
      pdfMake.createPdf(pdfData).open({}, newWindow);
      this.$emit("generatedFile");
    },
    textToBase64Barcode(text) {
      let canvas = document.createElement("canvas");
      JsBarcode(canvas, text, { format: "CODE128", displayValue: false });

      return canvas.toDataURL("image/png");
    },
    setTotal() {
      this.dataExport.products.forEach((element) => {
        this.sumRequestStockAmt += element.item_amt * 1;
      });
    },
    setProduct() {
      this.dataExport.products.forEach((element, index) => {
        if (index === 0) {
          this.newProduct.push([
            {
              border: [false, false, false, false],
              rowSpan: 2,
              fontSize: 7,
              text: "",
            },
            {
              border: [false, false, false, false],
              rowSpan: 2,
              fontSize: 7,
              text: "Item No",
            },
            {
              border: [false, false, false, false],
              rowSpan: 2,
              fontSize: 7,
              text: "Name",
            },
            {
              border: [false, false, false, false],
              rowSpan: 2,
              fontSize: 7,
              text: "UNIT",
              alignment: "right",
            },
            {
              border: [false, false, false, false],
              rowSpan: 2,
              fontSize: 7,
              text: "",
              alignment: "right",
            },
            {
              border: [false, false, false, false],
              rowSpan: 2,
              fontSize: 7,
              text: "Qty",
              alignment: "right",
            },
          ]);
          this.newProduct.push(["rowspan"]);
        }
        this.newProduct.push([
          {
            text: index + 1,
            fontSize: 7,
            alignment: "right",
            border: [false, false, false, true],
          },
          {
            text: element.code ? element.code : element.product.code,
            fontSize: 7,
            alignment: "left",
            border: [false, false, false, true],
          },
          {
            text: element.name ? element.name : element.product.name,
            fontSize: 7,
            alignment: "left",
            border: [false, false, false, true],
          },
          {
            text: element.isPcs == 1 ? "PIECES" : element.item_unit,
            fontSize: 7,
            alignment: "right",
            border: [false, false, false, true],
          },
          {
            // text: element.stockAmt ? element.stockAmt : "-",
            text: "",
            fontSize: 7,
            alignment: "right",
            border: [false, false, false, true],
          },
          {
            text: element.item_amt * 1,
            fontSize: 7,
            alignment: "right",
            border: [false, false, false, true],
          },
        ]);
      });
    },

    convertNumberToTh() {
      this.numberToTh = ArabicNumberToText(this.dataExport.final_price);
    },
  },
};
</script>
